import { Button } from 'flowbite-react';
import React from 'react';

export default function GradientButton({ text, width }) {
  return (
    <Button
      className={`border-[1px] border-customColorWhite/50 button-gradient hover:border-customColorGreen200 h-[40px] flex items-center justify-center transition duration-300 ${
        width ? width : 'w-fit px-8'
      }`}
    >
      {text}
    </Button>
  );
}
