'use client';

import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import GradientButton from '../GradientButton';
import { Dropdown } from 'flowbite-react';

export default function Navbar({ logoDesktop, navbarContent }) {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (name) => {
    setSelectedItem(name === selectedItem ? null : name);
  };

  const handleSubMenuItemClick = (name) => {
    setSelectedItem(name);
  };

  const clearSelectedItem = () => {
    setSelectedItem(null);
  };

  const navbarItems = navbarContent?.json_editor.navItems || '';
  const filteredNavItems = navbarItems.filter((item) => item.isButton !== true);
  const buttonNavItem = navbarItems.find((item) => item.isButton === true);

  return (
    <div className="w-full flex flex-row items-center justify-between">
      <div>
        <Link
          aria-label="botao-voltar-para-a-pagina-inicial"
          href="/"
          onClick={clearSelectedItem}
        >
          <Image
            width={210}
            height={50}
            src={logoDesktop.fileUrl}
            alt="Logotipo Valorizza"
            quality={100}
            priority
          />
        </Link>
      </div>
      <div className="w-fit flex flex-row items-center gap-10 fhd:gap-20">
        <div className="flex flex-row gap-12 items-center">
          {filteredNavItems &&
            filteredNavItems.map((c, index) => {
              if (c.subMenu?.length) {
                return (
                  <Dropdown
                    key={c.name}
                    className={`2xl:text-base fhd:text-lg text-customColorWhite bg-customColorWhite pr-10 pl-4 border-none text-shadow`}
                    theme={{
                      inlineWrapper:
                        selectedItem !== c.name
                          ? 'flex items-center 2xl:text-base fhd:text-lg font-normal text-customColorWhite hover:text-customColorGreen200 transition duration-300 text-shadow'
                          : 'flex items-center 2xl:text-base fhd:text-lg font-normal text-customColorWhite hover:text-customColorGreen200 transition duration-300 text-shadow',
                      floating: {
                        item: {
                          base: 'flex items-start py-2 text-[15px] text-customColorWhite hover:text-customColorGreen200 transition duration-300 cursor-pointer w-full focus:outline-none dark:hover:text-white dark:focus:bg-gray-600 dark:focus:text-white',
                        },
                      },
                    }}
                    label={c.name}
                    inline
                    onClick={() => handleItemClick(c.name)}
                  >
                    {c.subMenu.map((v) => (
                      <Link
                        key={v.name}
                        href={v.link}
                        aria-label={`Link para ${v.name}`}
                      >
                        <Dropdown.Item
                          className="text-customColorBlack100 hover:text-customColorGreen font-medium"
                          onClick={() => handleSubMenuItemClick(c.name)}
                        >
                          {v.name}
                        </Dropdown.Item>
                      </Link>
                    ))}
                  </Dropdown>
                );
              }
              return (
                <Link
                  className="transform-gpu transition duration-300 group"
                  name={`botão-barra-de-navegação-${c.name}`}
                  key={index}
                  target={c.isExternal ? '_blank' : '_self'}
                  href={c.link}
                  onClick={() => {
                    handleItemClick(c.name);
                  }}
                  aria-label={`Link para ${c.name}`}
                >
                  <h2 className="text-customColorWhite 2xl:text-base fhd:text-lg hover:text-customColorGreen200 transition duration-300 text-shadow">
                    {c.name}
                  </h2>
                </Link>
              );
            })}
          <Link
            className="w-full h-full"
            href={buttonNavItem.link}
            aria-label={`Link para ${buttonNavItem.name}`}
          >
            <GradientButton text={buttonNavItem.name} />
          </Link>
        </div>
      </div>
    </div>
  );
}
