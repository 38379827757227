'use client';

import React, { useState } from 'react';
import {
  faBars,
  faChevronDown,
  faChevronUp,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';

export default function Sidebar({ nav }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentOpenItem, setCurrentOpenItem] = useState(null);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleCollapsable = (item) => {
    if (currentOpenItem?.name === item.name) {
      setCurrentOpenItem(null);
      return;
    }

    setCurrentOpenItem(item);
  };

  return (
    <div className="relative h-full z-50">
      <div className="fixed top-[16px] right-4">
        <FontAwesomeIcon
          className="text-customColorWhite text-[26px]"
          icon={faBars}
          onClick={toggleSidebar}
        />
      </div>
      <div
        className={`fixed top-0 right-0 h-full w-[60%] overflow-scroll bg-custom-gradient transition-transform transform ${
          sidebarOpen
            ? 'translate-x-0 transition duration-300 rounded-tl-xl'
            : 'transition duration-300 translate-x-full rounded-tl-xl'
        }`}
      >
        <div className="p-2 text-end">
          <FontAwesomeIcon
            icon={faXmark}
            size="2x"
            className="text-customColorWhite"
            onClick={toggleSidebar}
          />
        </div>

        <div className="px-4 flex flex-col gap-3">
          <div>
            {nav.map((item, index) => {
              return (
                <div
                  key={`${item.link}-${index}`}
                  className="cursor-pointer items-center"
                >
                  {item.subMenu?.length > 0 ? (
                    <div className="mb-5">
                      <div
                        className="text-customColorWhite font-semibold"
                        onClick={() => toggleCollapsable(item)}
                      >
                        {item.name}{' '}
                        {currentOpenItem?.name === item.name ? (
                          <FontAwesomeIcon
                            size="xs"
                            className="px-2"
                            icon={faChevronUp}
                          />
                        ) : (
                          <FontAwesomeIcon
                            size="xs"
                            className="px-2"
                            icon={faChevronDown}
                          />
                        )}
                      </div>
                      {currentOpenItem === item &&
                        item.subMenu.map((subItem, index) => (
                          <div
                            key={`${subItem.link}-${index}`}
                            className="mb-3"
                          >
                            <Link
                              href={subItem.link || ''}
                              className="flex flex-col gap-5 px-2 text-customColorWhite font-semibold"
                              target={subItem.openBlank ? '_blank' : '_self'}
                              onClick={() => setSidebarOpen(false)}
                            >
                              <h2 className="pl-4 pt-2">{subItem.name}</h2>
                            </Link>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <Link
                      key={`${item.link}-${index}`}
                      href={item.link}
                      onClick={() => setSidebarOpen(false)}
                    >
                      <div className="mb-5 text-customColorWhite text-[16px] font-semibold">
                        <h2>{item.name}</h2>
                      </div>
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-[90%] h-[1px] bg-gradient-custom m-auto my-6" />
      </div>
    </div>
  );
}
