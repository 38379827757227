'use client';

import React, { Suspense } from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from '../../public/logobranco.svg';

export default function WhatsappFloatingButton({ whatsappContent }) {
  return (
    <div>
      <Suspense fallback={<div />}>
        <FloatingWhatsApp
          darkMode={true}
          phoneNumber={whatsappContent.textarea}
          accountName="Valorizza"
          chatMessage="Olá, como podemos te ajudar?"
          avatar={logo.src}
          statusMessage="Online"
          className="whatsappFloating"
          placeholder="Escrever..."
        />
      </Suspense>
    </div>
  );
}
