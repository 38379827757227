'use client';

import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';

export default function MainNavbar({ logotiposContent, navbarContent }) {
  const logoDesktop = logotiposContent.files.find(
    (c) => c.fileName === 'desktop'
  );
  const logoMobile = logotiposContent.files.find(
    (c) => c.fileName === 'mobile'
  );
  const nav = navbarContent?.json_editor.navItems || '';

  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <div className="w-full fixed top-0 z-40 bg-custom-gradient">
          <Link aria-label="botao-voltar-para-a-pagina-inicial" href="/">
            <div className="w-full h-full px-4 py-4">
              <Image
                width={30}
                height={50}
                src={logoMobile.fileUrl}
                alt="Logotipo Valorizza"
              />
            </div>
          </Link>
          <Sidebar nav={nav} />
        </div>
      ) : (
        <div className="w-full px-4 lg:px-14 fhd:px-20 py-2 absolute top-6 z-40">
          <div className="w-full flex flex-col gap-2">
            <Navbar logoDesktop={logoDesktop} navbarContent={navbarContent} />
          </div>
        </div>
      )}
    </>
  );
}
